import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Footer } from '~/components'
import * as st from '~/assets/styl/PrivacyPolicy.module.styl'
import cn from 'classnames'

const PrivacyPolicy = ({ location }: PageProps) => (
  <>
    <Head
      location={location}
      title="Política de Privacidade - Alínea Urbanismo"
    />

    <section className={st.content}>
      <header className={st.header}>
        <div className="container">
          <h1>Política de Privacidade</h1>
        </div>
      </header>
      <div className={cn('container', st.container)}>
        <h2>1. Objetivo</h2>
        <p>
          A política de privacidade da Alínea Urbanismo reflete sua cultura de
          integridade e as práticas de transparência e boa-fé, associada à
          importância atribuída ao cumprimento da Lei Federal n. 12.965/2014
          (Marco Civil da Internet), à Lei Geral de Proteção de Dados (Lei
          13.709/2018), aplicáveis às atividades desenvolvidas por nós
          <br />
          <br />
          Por este motivo, esta política foi implementada, com o intuito de
          informar os titulares, que neste caso correspondem aos clientes,
          leads, colaboradores, terceiros não colaboradores e menores de idade,
          sobre a forma como os seus dados são coletados e como se dá seu
          armazenamento, tratamento, uso, compartilhamento e eliminação, dentro
          do escopo dos nossos serviços.
          <br />
          <br />
        </p>
        <h2>2. Definições</h2>
        <ul>
          <li>
            ● <span>titular:</span> pessoa natural a quem se referem os dados
            pessoais que são objeto de tratamento;
          </li>
          <li>
            ● <span>dado pessoal:</span> informação relacionada à pessoa natural
            identificada ou identificável, como por exemplo nome, e-mail,
            telefone, dentre outros;
          </li>
          <li>
            ● <span>dado pessoal sensível:</span> dado pessoal sobre origem
            racial ou étnica, convicção religiosa, opinião política, filiação a
            sindicato ou a organização de caráter religioso, filosófico ou
            político, dado referente à saúde ou à vida sexual, dado genético ou
            biométrico, quando vinculado a uma pessoa natural;
          </li>
          <li>
            ● <span>tratamento:</span> toda operação realizada com dados
            pessoais, como as que se referem à coleta, produção, recepção,
            classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle da informação, modificação,
            comunicação, transferência, difusão ou extração;
          </li>
          <li>
            ● <span>controlador:</span> pessoa natural ou jurídica, de direito
            público ou privado, a quem competem as decisões referentes ao
            tratamento de dados pessoais;
          </li>
          <li>
            ● <span>encarregado:</span> pessoa indicada pelo controlador e
            operador para atuar como canal de comunicação entre o controlador,
            os titulares dos dados e a Autoridade Nacional de Proteção de Dados
            (ANPD);
          </li>
          <li>
            ● <span>operador:</span> pessoa natural ou jurídica, de direito
            público ou privado, que realiza o tratamento de dados pessoais em
            nome do controlador;
          </li>
          <li>
            ● <span>transferência internacional de dados:</span> transferência
            de dados pessoais para país estrangeiro ou organismo internacional
            do qual o país seja membro;
          </li>
          <li>
            ● <span>Autoridade Nacional de Proteção de Dados (ANPD:</span> órgão
            da administração pública federal, integrante da Presidência da
            República, que possui, dentre outras funções previstas pela Lei n.
            13.709/2018, a de zelar pela proteção dos dados pessoais, elaborar
            diretrizes para a Política Nacional de Proteção de Dados Pessoais e
            da Privacidade, fiscalizar e aplicar sanções em caso de tratamento
            de dados realizado em descumprimento à legislação, mediante processo
            administrativo que assegure o contraditório, a ampla defesa e o
            direito de recurso e promover na população o conhecimento das normas
            e das políticas públicas sobre proteção de dados pessoais e das
            medidas de segurança.
          </li>
          <li>
            ● <span>uso compartilhado de dados:</span> comunicação, difusão,
            transferência internacional, interconexão de dados pessoais ou
            tratamento compartilhado de bancos de dados pessoais por órgãos e
            entidades públicos no cumprimento de suas competências legais, ou
            entre esses e entes privados, reciprocamente, com autorização
            específica, para uma ou mais modalidades de tratamento permitidas
            por esses entes públicos, ou entre entes privados.
          </li>
          <li>
            ● <span>anonimização:</span> utilização de meios técnicos razoáveis
            e disponíveis no momento do tratamento, por meio dos quais um dado
            perde a possibilidade de associação, direta ou indireta, a um
            indivíduo.
          </li>
        </ul>
        <br />
        <h2>3. Lei Geral de Proteção de Dados</h2>
        <p>
          A Lei Geral de Proteção de Dados (Lei 13.709/2018) foi elaborada com o
          intuito de fixar regras claras sobre o tratamento de dados pessoais e
          assegurar o direito à privacidade e à proteção de dados pessoais dos
          titulares, com o uso de práticas de transparência e segurança.
          <br />
          <br />
          Desta forma, é possível permitir o desenvolvimento econômico e
          tecnológico e ainda garantir que o tratamento dos dados seja feito em
          consonância com os direitos fundamentais de liberdade, privacidade e
          livre desenvolvimento da personalidade.
          <br />
          <br />
          Ademais, a LGPD estabelece que no caso de ocorrência de conduta
          diversa daquela indicada pela lei, no âmbito da proteção de dados,
          como o vazamento de dados pessoais e o acesso não autorizado aos
          mesmos, é possível a responsabilização da Alínea Urbanismo, bem como
          daqueles diretamente envolvidos nos comportamentos condenados pela
          legislação brasileira, sem prejuízo de serem aplicadas as penalidades
          previstas na Política de Proteção de Dados da Alínea Urbanismo.
          <br />
          <br />
        </p>
        <h2>4. Princípios do Tratamento de Dados Pessoais</h2>
        <p>
          No processamento dos dados pessoais, a Alínea Urbanismo respeita os
          princípios estabelecidos no art. 6º, da Lei Geral de Proteção de Dados
          Pessoais, que fixam as seguintes premissas:
        </p>
        <br />
        <ul>
          <li>
            ● Os dados pessoais devem ser obtidos de forma justa e legal e com o
            direito à informação do titular dos dados, exceto se essas
            informações não forem necessárias considerando as hipóteses
            estabelecidas para o seu tratamento, se necessário, o consentimento
            expresso do Titular dos Dados deverá ser obtido;
          </li>
          <li>
            ● Os dados pessoais devem ser coletados apenas para propósitos
            especificados, explícitos e legítimos e não podem ser tratados de
            forma incompatível com esses propósitos, sendo que apenas serão
            disponibilizados a terceiros para os ditos propósitos ou de qualquer
            outra forma permitida pelas leis aplicáveis; Os controles e
            procedimentos técnicos e organizacionais apropriados devem ser
            implementados para garantir a segurança dos dados pessoais e evitar
            acesso ou divulgação não autorizados, que potencialmente poderiam
            resultar em alteração, destruição acidental ou ilegal, perda dos
            dados e contra todas as demais formas ilegais de Tratamento.
            Considerando as obrigações legais e boas práticas, as medidas de
            segurança devem ser elaboradas para garantir um nível de segurança
            apropriado aos riscos representados pelo tratamento e natureza dos
            dados pessoais a serem protegidos;
          </li>
          <li>
            ● Os dados pessoais coletados devem ser adequados, relevantes e não
            excessivos em relação aos propósitos para os quais são coletados
            e/ou serão processados;
          </li>
          <li>
            ● Os dados pessoais não podem ser retidos por um período maior que o
            necessário para os objetivos para os quais foram obtidos, a menos
            que exigido de outra forma pelas leis ou regulamentos aplicáveis ou
            quando houver consentimento específico autorizando o tratamento;
          </li>
          <li>
            ● Devem ser implementados procedimentos para garantir respostas
            imediatas às indagações dos titulares dos dados para assegurar que
            eles possam exercer adequadamente seu direito de acesso, retificação
            e recusa ao tratamento.
          </li>
        </ul>
        <br />

        <h2>5. Especificação dos dados e forma de coleta</h2>
        <p>
          A Alínea Urbanismo realiza o tratamento dos seguintes dados pessoais
          fornecidos diretamente pelo titular:
        </p>
        <ul>
          <li>
            ● Do cliente: dados pessoais para análise de novos negócios,
            elaboração de contratos, envio de e-mail marketing, consulta em
            sites de proteção ao crédito (SPC), emissão de boletos de cobrança e
            recibos, defesa em processo judicial, utilização dos serviços de
            corretores terceirizados, dados que devem ser fornecidos aos órgãos
            públicos e os dados necessários para realizar campanhas de marketing
            e site.
          </li>
          <li>
            ● De terceiros interessados e não clientes: é realizada a coleta
            dados necessários para análise de contratos com novos prestadores de
            serviços, elaboração de contrato com prestadores de serviço,
            utilização dos serviços de corretores terceirizados, envio de e-mail
            marketing e dados de corretores terceirizados.
          </li>
          <li>
            ● De candidatos a vagas de emprego: dados do currículo, como nome,
            e-mail, telefone e endereço.
          </li>
          <li>● Do colaborador: dados relativos à relação trabalhista.</li>
          <li>
            ● Do terceiro dependente do colaborador: dados dos cônjuges e
            dependentes do colaborador para adesão ao plano de saúde, como nome,
            RG, CPF, data de nascimento, nome dos genitores, endereço, dentre
            outros.
          </li>
        </ul>
        <br />
        <p>
          Além disso, a Alínea Urbanismo também realiza o tratamento de dados
          pessoais sensíveis. Tais dados são fornecidos diretamente pelos
          titulares, após terem consentido expressamente com o tratamento de
          dados u mediante coleta para estrito cumprimento de obrigação legal.
          Este tipo de dado poderá versar sobre:
        </p>
        <br />
        <ul>
          <li>● Saúde do colaborador e seus dependentes</li>
          <li>
            ● Origem racial ou étnica, crenças religiosas ou filosóficas do
            titular;
          </li>
          <li>● Dados genéticos ou biométricos;</li>
          <li>
            ● Outros dados específicos considerados sensíveis mediante as leis e
            regulamentos próprios.
          </li>
        </ul>
        <br />
        <p>
          Isto posto, é necessário esclarecer que os dados pessoais sensíveis
          tratados pela Alínea Urbanismo são dados de saúde e dados biométricos.
          Vale destacar que as informações referentes à saúde e os dados
          biométricos serão coletados unicamente para fins de adesão dos
          colaboradores e seus dependentes ao plano de saúde e registro de
          ponto.
        </p>
        <br />
        <h2>
          6. Finalidade do tratamento de dados pessoais e dados pessoais
          sensíveis
        </h2>
        <p>
          Em síntese, a LGPD trata da forma que as pessoas físicas e jurídicas,
          tanto de direito público, quanto de direito privado devem tratar dados
          pessoais aos quais tenham acesso. Desta forma, sempre que forem
          coletados dados dos titulares, deverá ser respeitada a finalidade
          específica para a qual os mesmos foram coletados e conforme informado
          ao titular do dado. No caso dos dados tratados pela Alínea Urbanismo,
          esta finalidade limita-se à:
        </p>
        <br />
        <h2>a) No caso dos clientes:</h2>
        <ul>
          <li>● realizar análise de novos negócios;</li>
          <li>● elaborar contratos;</li>
          <li>● enviar e-mail marketing;</li>
          <li>● entregar projetos e dados aos órgãos públicos;</li>
          <li>● realizar consulta em sites de proteção ao crédito;</li>
          <li>● emitir boletos de cobrança e recibos;</li>
          <li>● defesa em processo judicial, administrativo;</li>
          <li>● gerenciar o site e realizar campanhas de marketing</li>
          <li>
            ● prestação de serviços de corretores terceirizados, que inclui
            contatar e negociar diretamente com o cliente.
          </li>
        </ul>
        <br />
        <h2>b) No caso dos terceiros interessados e não clientes:</h2>
        <ul>
          <li>
            ● elaborar e realizar a análise de contratos com novos prestadores
            de serviços;
          </li>
          <li>● enviar e-mail marketing;</li>
          <li>● prestação de serviços de corretores terceirizados.</li>
        </ul>
        <br />
        <h2>
          c) No caso dos candidatos às vagas de emprego da Alínea Urbanismo:
        </h2>
        <ul>
          <li>● realizar processo seletivo</li>
        </ul>
        <br />
        <h2>d) No caso dos colaboradores:</h2>
        <ul>
          <li>● realizar atividades relacionadas à relação de emprego;</li>
          <li>● contratação de benefícios, como plano de saúde.</li>
        </ul>
        <br />
        <h2>e) Do terceiro dependente do colaborador:</h2>
        <ul>
          <li>
            ● prestação de informações à autoridade quando decorrente de
            cumprimento de obrigação legal;
          </li>
          <li>● contratação de benefícios, como plano de saúde.</li>
        </ul>
        <br />
        <p>
          A propósito, deve ser garantida ao titular a consulta gratuita sobre a
          forma e duração do tratamento dos dados, bem como saber quais dados
          estão sob a posse da Alínea Urbanismo. Destaca-se que, muito embora
          seja assegurado ao titular o acesso a meios para a correção e
          atualização dos dados, a Alínea Urbanismo não é responsável pela
          precisão, veracidade ou falta dela nas informações que lhe forem
          fornecidas ou pela sua desatualização, quando é de responsabilidade do
          titular prestá-las com exatidão ou atualizá-las. Por fim, cumpre
          esclarecer que todas as tecnologias utilizadas pela Alínea Urbanismo
          respeitarão sempre a legislação vigente e que o consentimento
          fornecido pelos titulares será coletado de forma individual, clara,
          específica e legítima.
        </p>
        <br />
        <h2>7. Prazo de retenção dos dados pessoais</h2>
        <p>
          O término do tratamento de dados pessoais ocorrerá nas seguintes
          hipóteses:
        </p>
        <ul>
          <li>
            ● verificação de que a finalidade foi alcançada ou de que os dados
            deixaram de ser necessários ou pertinentes ao alcance da finalidade
            específica almejada;
          </li>
          <li>● fim do período de tratamento;</li>
          <li>
            ● comunicação do titular, inclusive no exercício de seu direito de
            revogação do consentimento conforme disposto no § 5º do art. 8º
            desta Lei, resguardado o interesse público; ou
          </li>
          <li>
            ● determinação da autoridade nacional, quando houver violação ao
            disposto nesta Lei.
          </li>
        </ul>
        <br />
        <p>
          Por este motivo, a Alínea Urbanismo informa aos titulares que,
          transcorrido o prazo para o tratamento dos dados e, cumprida a sua
          finalidade, estes serão eliminados por meios seguros. Inclusive,
          conforme dito anteriormente, o titular também pode promover a
          revogação do consentimento a qualquer tempo para cessar o tratamento
          dos dados pessoais mediante solicitação ao e-mail
          privacidade@alineaurbanismo.com.br.
        </p>
        <br />
        <p>
          No entanto, eventualmente, os dados coletados pela Alínea Urbanismo
          poderão ser mantidos por período superior, por motivo de lei, ordem
          judicial, prevenção à fraude (art. 11, inciso II, alínea “a”, da Lei
          n. 13.709/2018), proteção ao crédito (art. 7º, inciso X, da LGPD) e
          outros interesses legítimos, previstos pelo art. 10, da LGPD.
        </p>
        <br />
        <h2>8. O controlador dos dados pessoais</h2>
        <p>
          A Alínea Urbanismo será a controladora dos dados pessoais relacionados
          neste documento. Para a finalidade da legislação aplicável,
          controlador é a quem compete as decisões relativas ao tratamento de
          dados pessoais. Ressaltamos que algumas empresas parceiras da Alínea
          Urbanismo poderão atuar como cocontroladoras dos dados pessoais e
          dados pessoais sensíveis dos colaboradores, clientes e leads da Alínea
          Urbanismo e seus dependentes, como, por exemplo, a Unimed e os
          corretores terceirizados.
        </p>
        <br />
        <h2>9. Compartilhamento dos dados pessoais</h2>
        <p>
          De acordo com a Lei Geral de Proteção de Dados, os dados pessoais
          coletados pautados no consentimento poderão ser compartilhados com
          terceiros desde que haja consentimento específico para tal (art. 7º, §
          5º, LGPD). Sendo assim, os dados pessoais coletados poderão ser
          compartilhados com parceiros da Alínea Urbanismo, com outras empresas
          do grupo, com o intuito de cumprir com as obrigações decorrentes dos
          serviços prestados pela empresa e pelo grupo, do contrato firmado com
          o titular e para:
        </p>
        <br />
        <ul>
          <li>
            ● Contratação de plano de saúde para colaboradores e dependentes;
          </li>
          <li>● Prestação de serviços por corretores terceirizados;</li>
          <li>● Gestão de dados em sistemas internos;</li>
          <li>● Contratação de serviços, como de assessoria contábil;</li>
          <li>● Emissão de recibos/boletos perante aos bancos;</li>
          <li>● Cumprimento de obrigações legais perante Órgãos Públicos;</li>
        </ul>
        <br />
        <p>
          Além disso, poderão ser compartilhados dados sem o consentimento
          quando por força de obrigação legal (inclusive com obrigações
          regulatórias), decisão judicial, intimação de órgão público ou
          governamental (de acordo com a legislação vigente), proteção da
          segurança nacional, prevenção de crimes e fraudes.
        </p>
        <br />
        <h2>10. Os seus direitos em relação aos seus dados pessoais</h2>
        <p>
          A Alínea Urbanismo se compromete a assegurar os direitos do titular em
          relação aos seus dados pessoais. São eles: a) a confirmação da
          existência de tratamento; b) o acesso aos dados; c) a correção de
          dados incompletos, inexatos ou desatualizados; d) a anonimização,
          bloqueio ou eliminação de dados desnecessários, excessivos ou tratados
          em desconformidade; e) a portabilidade a outro fornecedor de serviço
          ou produto, mediante requisição expressa; f) a informação das
          entidades públicas e privadas com as quais o controlador realizou o
          uso compartilhado de dados.
        </p>
        <br />
        <h2>11. Armazenamento e segurança</h2>
        <p>
          A Alínea Urbanismo informa, por meio deste instrumento, como se dará a
          segurança e sigilo dos dados fornecidos pelos usuários,
          considerando-se que as atividades de tratamento de dados pessoais são
          regidas pelos princípios da segurança e da prevenção, os quais
          asseguram ao titular a utilização de medidas aptas a proteger os dados
          pessoais de acessos não autorizados e de situações acidentais ou
          ilícitas de destruição, perda, alteração, comunicação ou difusão, bem
          como a adoção de medidas para prevenir a ocorrência de danos em
          virtude do tratamento de dados pessoais. A empresa se compromete em
          aplicar as medidas técnicas e organizativas para que os dados pessoais
          coletados sejam protegidos de acessos não autorizados, havendo estrito
          controle sobre o acesso mediante definição de responsabilidades de
          pessoas autorizadas para tal. A Alínea Urbanismo também assegura que
          os dados serão armazenados em servidor nacional (poderão eventualmente
          ser armazenados em servidor internacional - art. 33, inciso II, da
          LGPD) e, em atenção ao princípio da segurança, garante que serão
          adotadas as seguintes medidas de segurança, técnicas e administrativas
          para proteger os dados pessoais de situações acidentais ou ilícitas.
          Por fim, é importante ressaltar que a Alínea Urbanismo, no papel de
          controladora dos dados pessoais, comunicará à autoridade nacional e ao
          titular a ocorrência de incidente de segurança que possa acarretar
          risco ou dano relevante aos titulares.
        </p>
        <br />
        <h2>12. Práticas de Governança</h2>
        <p>
          De acordo com o art. 50, § 2º, da LGPD, o controlador poderá
          implementar programa de governança em privacidade e demonstrar a
          efetividade de seu programa de governança em privacidade quando
          apropriado, em observância à estrutura, à escala e ao volume de suas
          operações, bem como a sensibilidade dos dados tratados, a
          probabilidade e a gravidade dos danos para os titulares dos dados. Por
          esta razão, a Alínea Urbanismo adotou práticas de governança, com a
          implementação dos documentos indicados em suas políticas internas.
          Estes documentos asseguram o cumprimento de normas e boas práticas
          relativas à proteção de dados pessoais e também se aplicam a todos os
          dados pessoais sob o controle da mesma, além de estabelecer os planos
          de resposta a incidentes e remediação.
        </p>
        <br />
        <p>
          Ademais, a controladora implementou mecanismos de autenticação
          centralizados, criptografia, treinamentos para equipe da Alínea
          Urbanismo, dentre outros meios para garantir a proteção dos dados
          pessoais dos titulares. Com essa estrutura, a Alínea Urbanismo possui
          uma estratégia de proteção e privacidade de dados pessoais de acordo
          com os objetivos da empresa, de forma que os indivíduos responsáveis
          pelo tratamento façam a adesão às disposições aplicáveis dos
          regulamentos de proteção de dados e privacidade.
        </p>
        <br />
        <h2>13. Dúvidas | Contato</h2>
        <p>
          Em caso de dúvidas quanto à forma ou incompatibilidade de utilização
          dos dados com esta política ou com as suas escolhas enquanto titular,
          ou, ainda, se houver outras dúvidas, comentários ou sugestões, entre
          em contato através do endereço de e-mail
          privacidade@alineaurbanismo.com.br.
        </p>
        <br />
        <h2>14. Alterações</h2>
        <p>
          A Alínea Urbanismo se reserva o direito de alterar esta Política de
          Privacidade a qualquer momento, mediante publicação da versão
          atualizada no site https://www.alineaurbanismo.com.br/ (pode ser
          enviada por e-mail/SMS), a fim de garantir que esta reflita o real
          tratamento de dados pessoais realizado pela Empresa.
        </p>
        <br />
        <p>Data da última atualização: 02/05/2024.</p>
      </div>
    </section>

    <Footer />
  </>
)

export default PrivacyPolicy
